import { Typography } from 'components/Typography'
import styled from 'styled-components'

export const ButtonDockerWrapper = styled.div(
  ({
    theme: {
      primitiveTokens: {
        spacing,
        colors: { gray },
      },
    },
  }) => ({
    display: 'flex',
    flexFlow: 'column',
    padding: spacing[6],
    borderTop: `1px solid ${gray[10]}`,
  })
)

export const StyledTypography = styled(Typography)(
  ({
    theme: {
      primitiveTokens: { spacing },
    },
  }) => ({
    marginBottom: spacing[4],
  })
)

export const StyledButtonDockerButtons = styled.div({
  display: 'flex',
  flex: 1,
  flexFlow: 'row wrap',
  gap: 16,
  '.MuiButton-root': {
    flex: 1,
    maxWidth: 'none',
  },
  '.MuiButton-root + .MuiButton-root': {
    flexBasis: '100%',
  },
})
