import { HumanizedTokens } from 'utils'

type PrimaryVariationTokens = HumanizedTokens['components']['iconButton']['variants']['primary']

const enableState = (variationTokens: PrimaryVariationTokens) => `
  background-color: ${variationTokens.enable.background_color};
  color: ${variationTokens.enable.text_color};
`

const hoverState = (variationTokens: PrimaryVariationTokens) => `
  &:hover {
    background-color: ${variationTokens.hover.background_color};
  }
`

const disableState = (variationTokens: PrimaryVariationTokens) => `
  &.Mui-disabled {
    background-color: ${variationTokens.disable.background_color};
    color: ${variationTokens.disable.text_color};
  }
`

const activeState = (variationTokens: PrimaryVariationTokens) => `
  &:active {
    background-color: ${variationTokens.press.background_color};
  }
`

const focusState = (variationTokens: PrimaryVariationTokens) => `
  &:focus-visible:before {
    box-shadow: ${variationTokens.focus.box_shadow};
  }
`

const primaryStyles = (variationTokens: PrimaryVariationTokens) => `
  &.--primary {
    ${enableState(variationTokens)}
    ${hoverState(variationTokens)}
    ${disableState(variationTokens)}
    ${activeState(variationTokens)}
    ${focusState(variationTokens)}
  }
`

export { primaryStyles }
