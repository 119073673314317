import { FC } from 'react'
import { GridProps as MuiGridProps, GridSize } from '@material-ui/core'
import { StyledMuiGrid } from './style'

export interface GridProps extends Omit<MuiGridProps, 'spacing' | 'xs'> {
  xs?: 1 | 2 | 3 | 4
}

export const Grid: FC<GridProps> = ({ xs, ...props }) => (
  <StyledMuiGrid {...(xs && { xs: (xs * 3) as GridSize })} {...props} />
)
