import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

import { CircularProgressProps } from '.'

export const CircularColor = styled(CircularProgress)`
  color: ${({ theme: { primitiveTokens } }) => primitiveTokens.colors.gray[0]};
`

export const CircularBackground = styled(CircularProgress)`
  position: absolute;
  color: ${({ theme: { primitiveTokens } }) => primitiveTokens.colors.isaacBlue[80]};
`

export const CircularWrapper = styled.div<CircularProgressProps>`
  display: inline-flex;
  position: relative;
  &.--is-inversed {
    ${CircularColor} {
      color: ${({ theme: { primitiveTokens } }) => primitiveTokens.colors.isaacBlue[60]};
    }
    ${CircularBackground} {
      color: ${({ theme: { primitiveTokens } }) => primitiveTokens.colors.isaacBlue[30]};
    }
  }
`
