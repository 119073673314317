import React from 'react'
import { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton'

import { StyledMuiIconButton } from './index.style'

export interface TooltipButtonProps
  extends Omit<
    MuiIconButtonProps,
    | 'centerRipple'
    | 'color'
    | 'disabled'
    | 'disableFocusRipple'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'edge'
    | 'focusRipple'
    | 'size'
    | 'TouchRippleProps'
  > {}

export const TooltipButton = React.forwardRef<HTMLButtonElement, TooltipButtonProps>(
  (props, ref) => <StyledMuiIconButton {...props} ref={ref} />
) as React.ForwardRefExoticComponent<TooltipButtonProps & React.RefAttributes<HTMLButtonElement>>
