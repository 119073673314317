import styled from 'styled-components'

export const TableControlBar = styled.div(
  ({
    theme: {
      primitiveTokens: {
        colors: { gray },
        spacing,
      },
    },
  }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${spacing[3]} ${spacing[8]} ${spacing[5]}`,
    borderTop: `1px solid ${gray[5]}`,
    backgroundColor: gray[0],
  })
)
