import styled, { CSSObject } from 'styled-components'
import { StyledSearchClearProps } from './index'

const isNotVisibleStyles: CSSObject = {
  opacity: 0,
  visibility: 'hidden',
  cursor: 'pointer',
}

const isVisibleStyles: CSSObject = {
  opacity: 1,
  visibility: 'visible',
}

export const StyledSearchClear = styled.i<StyledSearchClearProps>(
  ({
    theme: {
      primitiveTokens: {
        colors: { gray },
      },
    },
    isVisible,
  }) => ({
    position: 'absolute',
    top: 20,
    right: 12,
    padding: 4,
    color: gray[40],
    cursor: 'pointer',
    transition: 'opacity 0.2s',
    svg: {
      verticalAlign: 'bottom',
      fontSize: '1rem',
    },
    ...(isVisible ? isVisibleStyles : isNotVisibleStyles),
  })
)
