import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField'

export interface TextFieldProps
  extends Omit<MuiTextFieldProps, 'classes' | 'color' | 'margin' | 'size' | 'variant'> {}

const muiProps: MuiTextFieldProps = {
  variant: 'outlined',
}

export const TextField = (props: TextFieldProps) => {
  return <MuiTextField {...muiProps} {...props} />
}
