import React from 'react'
import { LinkProps as MuiLinkProps } from '@material-ui/core/Link'

import { StyledMuiLink } from './index.style'

export interface LinkProps
  extends Omit<
    MuiLinkProps,
    | 'color'
    | 'gutterBottom'
    | 'noWrap'
    | 'paragraph'
    | 'TypographyClasses'
    | 'underline'
    | 'variant'
    | 'variantMapping'
  > {}

export const Link = ({ ...props }: LinkProps) => <StyledMuiLink {...props} variant="body2" />
