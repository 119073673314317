import React from 'react'
import styled from 'styled-components'

export const DialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({
    theme: {
      humanizedTokens: {
        components: {
          dialog: { actions },
        },
      },
    },
  }) => `
    gap: ${actions.gap};
    padding: ${actions.padding};
    border-top: ${actions.border};
  `}
`
