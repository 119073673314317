import styled from 'styled-components'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'

import { HumanizedTokens } from 'utils'
import { primaryStyles } from './Primary.style'
import { ghostStyles } from './Ghost.style'
import { sizeStyles } from 'components/IconButton/Sizes.style'

type IconButtonBaseTokens = HumanizedTokens['components']['iconButton']['base']

const baseButton = (baseTokens: IconButtonBaseTokens) => `
  position: relative;
  height: 48px;
  border-radius: ${baseTokens.border_radius};
  text-transform: none;
  padding: ${baseTokens.padding};
  transition: ${baseTokens.transitions.base};
  &:before {
    content: '';
    transition: box-shadow 0.2s;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: ${baseTokens.border_radius};
    box-shadow: ${baseTokens.box_shadow};
    transition: ${baseTokens.transitions.focus};
  }
`

export const StyledMuiIconButton = styled(IconButton)<IconButtonProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: {
          iconButton: { base, variants, sizes },
        },
      },
    },
  }) => `
    ${baseButton(base)}
    ${primaryStyles(variants.primary)}
    ${ghostStyles(variants.ghost)}
    ${sizeStyles(sizes)}
  `}
`
