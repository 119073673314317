import { FC, HTMLAttributes } from 'react'
import { Cancel } from '@material-ui/icons'

import { StyledSearchClear as StyledStyledSearchClear } from './styles'

export interface StyledSearchClearProps extends HTMLAttributes<HTMLElement> {
  isVisible: boolean
}

export const StyledSearchClear: FC<StyledSearchClearProps> = props => (
  <StyledStyledSearchClear {...props}>
    <Cancel />
  </StyledStyledSearchClear>
)
