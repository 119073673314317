import styled from 'styled-components'
import Breadcrumbs, { BreadcrumbsProps } from '@material-ui/core/Breadcrumbs'

import { HumanizedTokens } from 'utils'

type BreadcrumbsTokens = HumanizedTokens['components']['breadcrumbs']
type LinkTokens = HumanizedTokens['components']['link']
type LinkHoverTokens = LinkTokens['hover']
type LinkPressedTokens = LinkTokens['pressed']

const base = (tokens: BreadcrumbsTokens) => `
  .MuiLink-root {
    color: ${tokens.base.color};
  }

  .MuiBreadcrumbs-li {
    &:last-child {
      .MuiLink-root {
        color: ${tokens.current.color};
      }
    }
  }
`

const hoverState = (tokens: LinkHoverTokens) => `
  .MuiLink-root,
  .MuiBreadcrumbs-li:last-child .MuiLink-root {
    &:hover {
      color: ${tokens.color};
    }
  }
`

const pressedState = (tokens: LinkPressedTokens) => `
.MuiLink-root,
  .MuiBreadcrumbs-li:last-child .MuiLink-root {
    &:active {
      color: ${tokens.color};
    }
  }
`

export const StyledMuiBreadcrumbs = styled(Breadcrumbs)<BreadcrumbsProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: {
          breadcrumbs,
          link: { hover, pressed },
        },
      },
    },
  }) => `
    ${base(breadcrumbs)}
    ${hoverState(hover)}
    ${pressedState(pressed)}
  `}
`
