import styled from 'styled-components'
import { brandPrimitiveTokens } from 'utils'

const {
  colors: { gray },
} = brandPrimitiveTokens

export interface SideMenuSearchProps {
  isVisible: boolean
}

const isVisibleStyle = `
  opacity: 1;
  left: 0;
  transition: opacity 0.1s, left 0s 0s;
`

export const SideMenuSearch = styled.div<SideMenuSearchProps>(
  ({ isVisible }) => `
    opacity: 0;
    position: absolute;
    top: 102px;
    left: -999%;
    display: flex;
    flex-flow: column wrap;
    height: calc(100% - 102px);
    width: 100%;
    background-color: ${gray[3]};
    transition: opacity 0.1s, left 0s 0.3s;
    ${isVisible === true ? isVisibleStyle : ''}
`
)
