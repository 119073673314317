import styled from 'styled-components'
import SearchRounded from '@material-ui/icons/SearchRounded'

export const StyledSearchWrapper = styled.div({
  position: 'relative',
})

export const StyledSearchIcon = styled(SearchRounded)(
  ({
    theme: {
      primitiveTokens: {
        colors: { gray },
      },
    },
  }) => ({
    position: 'absolute',
    top: '50%',
    left: 16,
    transform: 'translateY(-50%)',
    color: gray[40],
    transition: '0.3s color',
  })
)

export const StyledSearchInput = styled.input(
  ({
    theme: {
      humanizedTokens: {
        components: {
          typography: {
            variation: { subtitleDesktopMedium },
          },
        },
      },
      primitiveTokens: {
        colors: { gray, isaacBlue },
      },
    },
  }) => ({
    ...subtitleDesktopMedium,
    '::placeholder': {
      color: gray[40],
    },
    width: '100%',
    height: 68,
    padding: '0 16px 0 52px',
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${gray[50]}`,
    color: gray[80],
    transition: '0.3s border',
    ':hover': {
      borderBottom: `1px solid ${gray[70]}`,
    },
    ':focus': {
      outline: 'none',
      borderColor: isaacBlue[30],
      [`+ ${StyledSearchIcon}`]: {
        color: isaacBlue[60],
      },
    },
    ':disabled': {
      borderColor: gray[20],
      color: gray[20],
      '::placeholder': {
        color: gray[20],
      },
      [`+ ${StyledSearchIcon}`]: {
        color: gray[20],
      },
    },
  })
)
