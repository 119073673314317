import { ReactNode } from 'react'
import { SideMenuSearchResult as StyledSideMenuSearchResult } from './styles'

interface SideMenuSearchResultProps {
  children: ReactNode[]
}

export const SideMenuSearchResult = ({ children }: SideMenuSearchResultProps) => (
  <StyledSideMenuSearchResult>{children}</StyledSideMenuSearchResult>
)
