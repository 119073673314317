import { ReactNode } from 'react'

import {
  StyledSideMenuNav,
  StyledSideMenuUl,
} from 'components/SideMenu/components/SideMenuList/styles'

export const SideMenuList = ({ children }: { children: ReactNode }) => (
  <StyledSideMenuNav>
    <StyledSideMenuUl>{children}</StyledSideMenuUl>
  </StyledSideMenuNav>
)
