import { HumanizedTokens } from 'utils'

type GhostVariationTokens = HumanizedTokens['components']['iconButton']['variants']['ghost']

const hoverState = (variationTokens: GhostVariationTokens) => `
  &:hover {
    background-color: ${variationTokens.hover.background_color};
  }
`

const disableState = (variationTokens: GhostVariationTokens) => `
  &.Mui-disabled {
    background-color: ${variationTokens.disable.background_color};
    color: ${variationTokens.disable.text_color};
  }
`

const activeState = (variationTokens: GhostVariationTokens) => `
  &:active {
    background-color: ${variationTokens.press.background_color};
  }
`

const focusState = (variationTokens: GhostVariationTokens) => `
  &:focus-visible:before {
    box-shadow: ${variationTokens.focus.box_shadow};
  }
`

const ghostStyles = (variationTokens: GhostVariationTokens) => `
  &.--ghost {
    ${hoverState(variationTokens)}
    ${disableState(variationTokens)}
    ${activeState(variationTokens)}
    ${focusState(variationTokens)}
  }
`

export { ghostStyles }
