import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import { ButtonProps } from '.'
import { CircularWrapper } from 'components/CircularProgress/CircularProgress.style'
import { HumanizedTokens } from 'utils'

type ButtonTokens = HumanizedTokens['components']['button']
type PrimaryVariationTokens = ButtonTokens['primary']
type GhostVariationTokens = ButtonTokens['ghost']

const baseButton = (buttonTokens: ButtonTokens) => `
  position: relative;
  min-width: 80px;
  max-width: 290px;
  height: 48px;
  border-radius: 8px;
  text-transform: none;
  padding: ${buttonTokens.base.padding};
  transition: ${buttonTokens.base.transitions.base};
  &:before {
    content: '';
    transition: box-shadow 0.2s;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 8px;
    box-shadow: ${buttonTokens.base.box_shadow};
    transition: ${buttonTokens.base.transitions.focus};
  }
`

const startIcon = (button: ButtonTokens) => `
  &.--with-icon {
    padding: ${button.withIcon.padding};
    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 12px;
    }
  }
`

const fullWidth = `
  &.MuiButton-fullWidth {
    max-width: 100%
  }
`

const loadingBaseState = `
  &.--is-loading {
    .MuiButton-label {
      color: transparent;
    }
    ${CircularWrapper} {
      position: absolute;
    }
  }
`

const primaryDisabledState = (primary: PrimaryVariationTokens) => `
  &.Mui-disabled {
    background-color: ${primary.disable.background_color};
    color: ${primary.disable.text_color};
  }
`

const primaryLoadingState = (primary: PrimaryVariationTokens) => `
  &.--is-loading {
    &.Mui-disabled {
      background-color: ${primary.load.background_color};
    }
  }
`

const primaryActiveState = (primary: PrimaryVariationTokens) => `
  &:active {
    background-color: ${primary.press.background_color};
  }
`

const primaryFocusState = (primary: PrimaryVariationTokens) => `
  &:focus-visible:before {
    box-shadow: ${primary.focus.box_shadow};
  }
`

const primary = (button: ButtonTokens) => `
  &.--primary {
    ${primaryDisabledState(button.primary)}
    ${primaryLoadingState(button.primary)}
    ${primaryActiveState(button.primary)}
    ${primaryFocusState(button.primary)}
  }
`

const ghostHoverState = (ghost: GhostVariationTokens) => `
  &:hover {
    background-color: ${ghost.hover.background_color}
  }
`

const ghostDisabledState = (ghost: GhostVariationTokens) => `
  &.Mui-disabled {
    background-color: ${ghost.disable.background_color};
    color: ${ghost.disable.text_color};
  }
`

const ghostActiveState = (ghost: GhostVariationTokens) => `
  &:active {
    background-color: ${ghost.press.background_color};
  }
`

const ghostFocusState = (ghost: GhostVariationTokens) => `
  &:focus-visible:before {
    box-shadow: ${ghost.focus.box_shadow};
  }
`

const ghost = (button: ButtonTokens) => `
  &.--ghost {
    ${ghostHoverState(button.ghost)}
    ${ghostDisabledState(button.ghost)}
    ${ghostActiveState(button.ghost)}
    ${ghostFocusState(button.ghost)}
  }
`

export const StyledMuiButton = styled(Button)<ButtonProps>`
  ${fullWidth}
  ${loadingBaseState}
  ${({
    theme: {
      humanizedTokens: {
        components: { button },
      },
    },
  }) => `
    ${baseButton(button)}
    ${startIcon(button)}
    ${primary(button)}
    ${ghost(button)}
  `}
`
