import React from 'react'
import styled from 'styled-components'

export const DialogTitle = styled.h2`
  flex: 0 0 auto;
  ${({
    theme: {
      humanizedTokens: {
        components: {
          dialog: { title },
        },
      },
    },
  }) => `
    color: ${title.color};
    font: ${title.font};
    margin: ${title.margin};
  `}
`
