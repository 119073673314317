import styled from 'styled-components'

import { hexColorToRGBA } from 'utils/helpers/colors'

export const SideMenuSearchResult = styled.ul({
  flex: 1,
  margin: 0,
  padding: 0,
  maxHeight: 'calc(100% - 68px)',
  listStyle: 'none',
  overflow: 'scroll',
})

export const SideMenuSearchResultLi = styled.li(
  ({
    theme: {
      humanizedTokens: {
        components: {
          typography: {
            variation: { subtitleDesktopMedium },
          },
        },
      },
      primitiveTokens: {
        colors: { isaacBlue, gray },
      },
    },
  }) => {
    return {
      padding: '12px 16px',
      ...subtitleDesktopMedium,
      color: gray[80],
      cursor: 'pointer',
      ':hover': {
        backgroundColor: hexColorToRGBA(isaacBlue[60], 0.1),
      },
      ':active': {
        backgroundColor: hexColorToRGBA(isaacBlue[60], 0.14),
      },
    }
  }
)
