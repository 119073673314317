import styled from 'styled-components'
import Switch from '@material-ui/core/Switch'
import { SwitchProps } from '.'

import { HumanizedTokens } from 'utils'

type SwitchTokens = HumanizedTokens['components']['baseSwitch']

const baseComponent = `
  border: 2px solid transparent;
  border-radius: 2px;
  width: 39px;
  height: 26px;
  padding: 0;
`

const baseTrack = (bs: SwitchTokens) => `
  .MuiSwitch-track {
    background: ${bs.track.off.backgroundColor};
    opacity: 0.2;
    margin: 4px 0;
    height: 14px;
    width: 35px;
  }
`

const baseChecked = (bs: SwitchTokens) => `
  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(13px);
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: ${bs.track.on.backgroundColor};
    opacity: 0.2;
  }
`

const iconButton = (bs: SwitchTokens) => `
  .MuiIconButton-root {
    padding: 0;

    .MuiSwitch-thumb {
      background: ${bs.thumb.off.backgroundColor};
      box-shadow: none;
      border: 1px solid ${bs.thumb.borderColor};
    }
    &.Mui-checked .MuiSwitch-thumb {
      background: ${bs.thumb.on.backgroundColor};
    }

    &:hover {
      background: transparent;
      .MuiSwitch-thumb {
        background: ${bs.thumb.off.hover.backgroundColor};
      }
      &.Mui-checked .MuiSwitch-thumb {
        background: ${bs.thumb.on.hover.backgroundColor};
      }
    }

    &:active {
      .MuiSwitch-thumb {
        background: ${bs.thumb.off.active.backgroundColor};
      }
      &.Mui-checked .MuiSwitch-thumb {
        background: ${bs.thumb.on.active.backgroundColor};
      }
    }

    &.Mui-disabled {
      .MuiSwitch-thumb {
        background: ${bs.thumb.off.disabled.backgroundColor};
      }
      &.Mui-checked .MuiSwitch-thumb {
        background: ${bs.thumb.on.disabled.backgroundColor};
      }
      &.MuiSwitch-switchBase + .MuiSwitch-track {
        background: ${bs.track.off.disabled.backgroundColor};
        opacity: 0.2;
      }
      &.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background: ${bs.track.on.disabled.backgroundColor};
        opacity: 0.2;
      }
    }
  }
`

const focus = (bs: SwitchTokens) => `
  &.CustomFocusVisible {
    border: 2px solid ${bs.focus.borderColor};
    outline: none;
  }
`

export const StyledMuiSwitch = styled(Switch)<SwitchProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: { baseSwitch },
      },
    },
  }) => `
    ${baseComponent}
    ${baseTrack(baseSwitch)}
    ${baseChecked(baseSwitch)}
    ${iconButton(baseSwitch)}
    ${focus(baseSwitch)}
  `}
`
