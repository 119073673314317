import { HumanizedTokens } from 'utils'

type SizeTokens = HumanizedTokens['components']['iconButton']['sizes']

const smallStyles = (sizeTokens: SizeTokens['small']) => `
  &.--small {
    height: ${sizeTokens.size};
    width: ${sizeTokens.size};
    padding: ${sizeTokens.padding};
    .MuiSvgIcon-root {
      font-size: ${sizeTokens.iconSize};
    }
  }
`

const largeStyles = (sizeTokens: SizeTokens['large']) => `
  &.--large {
    width: ${sizeTokens.size};
  }
`

const sizeStyles = (sizeTokens: SizeTokens) => `
  ${smallStyles(sizeTokens.small)}
  ${largeStyles(sizeTokens.large)}
`

export { sizeStyles }
