import styled from 'styled-components'
import { hexColorToRGBA } from 'utils/helpers/colors'

export const SideMenuSearchInput = styled.input(
  ({
    theme: {
      humanizedTokens: {
        components: {
          typography: {
            variation: { subtitleDesktopMedium },
          },
        },
      },
      primitiveTokens: {
        colors: { gray, isaacBlue },
      },
    },
  }) => ({
    ...subtitleDesktopMedium,
    '::placeholder': {
      color: gray[40],
    },
    width: '100%',
    height: 68,
    padding: '20px 52px 20px 16px',
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${gray[50]}`,
    color: gray[80],
    transition: '0.3s border',
    ':focus': {
      outline: 'none',
      borderBottom: `1px solid ${isaacBlue[30]}`,
    },
  })
)
