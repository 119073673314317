import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html  {
    box-sizing: border-box;
  }
  * {
    box-sizing: inherit;
  }
  .MuiPickersBasePicker-pickerView {
    min-width: auto;
    padding: 5px;
    min-height: 280px;
  }
`
