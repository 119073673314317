import styled from 'styled-components'
import Grid, { GridProps } from '@material-ui/core/Grid'

export const StyledMuiGrid = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    width: calc(100% + 24px);
    margin: -12px;
  }
  .MuiGrid-item {
    padding: 12px;
  }

  @media screen and (min-width: 601px) {
    &.MuiGrid-container {
      width: calc(100% + 32px);
      margin: -16px;
    }
    .MuiGrid-item {
      padding: 16px;
    }
  }
`
