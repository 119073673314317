import styled from 'styled-components'
import Radio, { RadioProps } from '@material-ui/core/Radio'

import { HumanizedTokens } from 'utils'

type FormControlLabelTokens = HumanizedTokens['components']['checkbox']

const root = (tokens: FormControlLabelTokens['base']) => `
  padding: 0;
  margin-right: ${tokens.margin_right};

  &:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: ${tokens.before.border_radius};;
    box_shadow: 0 0 0 2px transparent;
    transitions: ${tokens.before.transition};
  }
`

const hoverState = (tokens: FormControlLabelTokens['hover']) => `
  &:hover {
    background-color: transparent;
    color: ${tokens.color};
  }
`

const pressState = (tokens: FormControlLabelTokens['press']) => `
  &:active {
    color: ${tokens.color};
  }
`

const focusState = (tokens: FormControlLabelTokens['focus']) => `
  &.Mui-focusVisible {
    &:before {
      box-shadow: ${tokens.box_shadow};
    }
  }
`

const isChecked = (tokens: FormControlLabelTokens['checked']) => `
  &.Mui-checked {
    color: ${tokens.color};
    &:hover {
      color: ${tokens.hover.color};
      background-color: transparent;
    }

    &:active {
      color: ${tokens.press.color};
    }

    &.Mui-disabled {
      color: ${tokens.disable.color}
    }
  }
`

const isDisabled = (tokens: FormControlLabelTokens['disable']) => `
  &.Mui-disabled {
    color: ${tokens.color};
    .MuiFormControlLabel-label {
      color: ${tokens.label.color};
    }
  }
`

export const StyledRadio = styled(Radio)<RadioProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: {
          checkbox: { base, checked, disable, focus, hover, press },
        },
      },
    },
  }) => `
    ${root(base)}
    ${hoverState(hover)}
    ${pressState(press)}
    ${focusState(focus)}
    ${isChecked(checked)}
    ${isDisabled(disable)}
  `}
`
