import { useState } from 'react'

export const usePagination = (
  initialPage = 1,
  initialItensPerPage = 10,
  initialItensPerPageOptions = [10, 15, 25, 50]
) => {
  const [page, setPage] = useState(initialPage)
  const [itensPerPage, setItensPerPage] = useState(initialItensPerPage)
  return { page, setPage, itensPerPage, setItensPerPage, initialItensPerPageOptions }
}
