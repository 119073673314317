import styled, { CSSObject } from 'styled-components'

const sharedWrapperStyle: CSSObject = {
  display: 'flex',
  gap: 24,
  alignItems: 'center',
}

export const StyledPaginationWrapper = styled.div(sharedWrapperStyle)

export const StyledPageActionWrapper = styled.div(sharedWrapperStyle)

export const StyledItensPerPageWrapper = styled.div({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  'legend, label': {
    display: 'none',
  },
  fieldset: {
    top: 0,
  },
  '.MuiSelect-selectMenu': {
    boxSizing: 'border-box',
    width: 72,
    minHeight: 0,
    padding: '6px 32px 6px 12px',
    lineHeight: 1,
    fontSize: '0.875rem',
  },
  '.MuiSelect-iconOutlined': {
    right: 0,
  },
})
