import styled from 'styled-components'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'

import { HumanizedTokens } from 'utils'

type SystemButtonTokens = HumanizedTokens['components']['systemButton']

const baseButton = (baseTokens: SystemButtonTokens['base']) => `
  position: relative;
  height: 28px;
  border-radius: ${baseTokens.border_radius};
  text-transform: none;
  padding: ${baseTokens.padding};
  transition: ${baseTokens.transitions.base};
  .MuiSvgIcon-root {
    font-size: ${baseTokens.font_size}
  }
`

const disableState = (disableTokens: SystemButtonTokens['disable']) => `
  &.Mui-disabled {
    background-color: ${disableTokens.background_color};
    color: ${disableTokens.text_color};
  }
`

const enableState = (enableTokens: SystemButtonTokens['enable']) => `
  color: ${enableTokens.text_color};
`

const focusState = (focusTokens: SystemButtonTokens['focus']) => `
  &:focus-visible {
    background-color: ${focusTokens.background_color};
    color: ${focusTokens.text_color};
  }
`

const hoverState = (hoverTokens: SystemButtonTokens['hover']) => `
  &:hover {
    background-color: ${hoverTokens.background_color};
    color: ${hoverTokens.text_color};
  }
`

const pressState = (pressTokens: SystemButtonTokens['press']) => `
  &:active {
    background-color: ${pressTokens.background_color};
    color: ${pressTokens.text_color};
  }
`

export const StyledMuiIconButton = styled(IconButton)<IconButtonProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: {
          systemButton: { base, disable, enable, focus, hover, press },
        },
      },
    },
  }) => `
    ${baseButton(base)}
    ${enableState(enable)}
    ${hoverState(hover)}
    ${disableState(disable)}
    ${focusState(focus)}
    ${pressState(press)}
  `}
`
