import styled from 'styled-components'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'

import { HumanizedTokens } from 'utils'

type TooltipButtonTokens = HumanizedTokens['components']['tooltipButton']

const baseButton = (baseTokens: TooltipButtonTokens['base']) => `
  position: relative;
  border-radius: ${baseTokens.border_radius};
  text-transform: none;
  padding: ${baseTokens.padding};
  transition: ${baseTokens.transitions.base};
  cursor: help;
`

const enableState = (enableTokens: TooltipButtonTokens['enable']) => `
  color: ${enableTokens.text_color};
`

const hoverState = (hoverTokens: TooltipButtonTokens['hover']) => `
  &:hover {
    background-color: ${hoverTokens.background_color};
    color: ${hoverTokens.text_color};
  }
`

export const StyledMuiIconButton = styled(IconButton)<IconButtonProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: {
          tooltipButton: { base, enable, hover },
        },
      },
    },
  }) => `
    ${baseButton(base)}
    ${enableState(enable)}
    ${hoverState(hover)}
  `}
`
