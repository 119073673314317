import React from 'react'
import { CircularProgressProps as MuiCircularProgressProps } from '@material-ui/core/CircularProgress'
import classNames from 'classnames'

import { CircularWrapper, CircularBackground, CircularColor } from './CircularProgress.style'

export interface CircularProgressProps
  extends Pick<MuiCircularProgressProps, 'size' | 'thickness' | 'className'> {
  inverse?: boolean
}

export const CircularProgress = ({
  inverse = false,
  size = 18,
  thickness = 5,
}: CircularProgressProps) => (
  <CircularWrapper inverse={inverse} className={classNames({ '--is-inversed': inverse })}>
    <CircularBackground variant="determinate" value={100} size={size} thickness={thickness} />
    <CircularColor disableShrink size={size} thickness={thickness} />
  </CircularWrapper>
)
