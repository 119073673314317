import styled from 'styled-components'

export const StyledSideMenuNav = styled.nav``

export const StyledSideMenuUl = styled.ul`
  display: flex;
  flex-flow: column;
  height: 100%;
  list-style: none;
  margin: 0 16px;
  padding: 0 0 16px;
`
