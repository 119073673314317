import React from 'react'
import { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton'

import { StyledMuiIconButton } from './index.style'

export interface SystemButtonProps
  extends Omit<
    MuiIconButtonProps,
    | 'centerRipple'
    | 'color'
    | 'disableFocusRipple'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'edge'
    | 'focusRipple'
    | 'size'
    | 'TouchRippleProps'
  > {}

export const SystemButton = React.forwardRef<HTMLButtonElement, SystemButtonProps>((props, ref) => (
  <StyledMuiIconButton {...props} ref={ref} />
)) as React.ForwardRefExoticComponent<SystemButtonProps & React.RefAttributes<HTMLButtonElement>>
