import { RadioProps as MuiRadioProps } from '@material-ui/core/Radio'
import { StyledRadio } from './style'

export interface RadioProps
  extends Omit<
    MuiRadioProps,
    | 'centerRipple'
    | 'color'
    | 'checkedIcon'
    | 'disableFocusRipple'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'edge'
    | 'focusRipple'
    | 'icon'
    | 'indeterminate'
    | 'indeterminateIcon'
    | 'size'
    | 'TouchRippleProps'
  > {}

export const Radio = (props: RadioProps) => {
  return <StyledRadio color="primary" {...props} disableRipple />
}
