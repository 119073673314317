export const BrandLogo = () => (
  <svg width="56" height="18" viewBox="0 0 56 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.03481 4.07298C3.1586 4.07298 4.06961 3.16121 4.06961 2.03649C4.06961 0.911768 3.1586 0 2.03481 0C0.911014 0 0 0.911768 0 2.03649C0 3.16121 0.911014 4.07298 2.03481 4.07298Z"
      fill="#3D4ED7"
    />
    <path
      d="M49.0594 13.1165C48.5721 12.6203 48.3252 11.9755 48.3252 11.2003C48.3252 10.4251 48.5721 9.77995 49.0594 9.28415C49.5468 8.78835 50.1644 8.53439 50.8938 8.53439C51.8446 8.53439 52.5663 8.89777 53.0431 9.6128L55.9981 7.90496C55.5012 7.10091 54.8106 6.43455 53.9895 5.96698C53.0734 5.43848 52.0402 5.16797 50.9176 5.16797C49.1881 5.16797 47.7282 5.75178 46.5781 6.90407C45.4279 8.05636 44.8434 9.50056 44.8434 11.1999C44.8434 12.8993 45.4267 14.3447 46.5781 15.4954C47.7294 16.646 49.1877 17.2315 50.9176 17.2315C52.0398 17.2315 53.0734 16.9626 53.9895 16.4325C54.8154 15.9615 55.5074 15.2872 55.9997 14.4735L53.0508 12.7693C52.5982 13.496 51.874 13.8667 50.8938 13.8667C50.1644 13.8658 49.5472 13.6139 49.0594 13.1165Z"
      fill="#3D4ED7"
    />
    <path
      d="M29.413 13.7722V16.9598H28.4851C26.859 16.9598 25.7746 16.3945 25.2776 15.2866C24.397 16.4849 23.0867 17.2307 21.4831 17.2307C18.3208 17.2307 15.9265 14.6314 15.9265 11.1951C15.9265 7.7588 18.3224 5.16797 21.4847 5.16797C22.9076 5.16797 24.1049 5.75582 24.963 6.70543V5.43727H28.2608V12.8452C28.2608 13.433 28.5997 13.7738 29.1887 13.7738L29.413 13.7722ZM24.963 11.1951C24.963 9.56758 23.766 8.35352 22.1391 8.35352C20.5351 8.35352 19.3382 9.56758 19.3382 11.1951C19.3382 12.8226 20.5351 14.0435 22.1391 14.0435C23.766 14.0435 24.963 12.8226 24.963 11.1951Z"
      fill="#3D4ED7"
    />
    <path
      d="M43.884 13.7718V16.9593H42.9562C41.3296 16.9593 40.2457 16.3941 39.7487 15.2866C38.8676 16.4845 37.5577 17.2307 35.9538 17.2307C32.7918 17.2307 30.3976 14.6309 30.3976 11.1947C30.3976 7.7584 32.7938 5.16797 35.9558 5.16797C37.379 5.16797 38.578 5.75582 39.4344 6.70503V5.43727H42.7323V12.8452C42.7323 13.433 43.0712 13.7738 43.6601 13.7738L43.884 13.7718ZM39.4344 11.1947C39.4344 9.56718 38.2375 8.35352 36.6105 8.35352C35.007 8.35352 33.8096 9.56718 33.8096 11.1947C33.8096 12.8222 35.007 14.0431 36.6105 14.0431C38.2375 14.0431 39.4344 12.8226 39.4344 11.1947Z"
      fill="#3D4ED7"
    />
    <path
      d="M10.7071 9.62478C10.3944 9.53474 10.0991 9.44955 9.81433 9.3579C9.62835 9.29815 9.06196 9.11565 9.06196 8.67153C9.06196 8.34612 9.24713 7.95771 10.1302 7.95771C10.7777 7.95771 11.2948 8.27708 11.6688 8.90692L14.5863 7.5903C14.1554 6.91322 13.6883 6.31487 12.9928 5.89457C11.4195 4.94052 8.99016 4.92114 7.34262 5.85016C6.50474 6.32254 5.50589 7.23662 5.50589 8.90853C5.50589 9.97684 5.92544 10.7658 6.82585 11.392C7.63026 11.9508 8.65815 12.2802 9.67273 12.6C9.97004 12.6936 10.2508 12.7821 10.5247 12.8757C11.0121 13.0433 11.2589 13.2859 11.2589 13.5964C11.2589 13.9739 11.0411 14.4237 10.0035 14.4237C9.07043 14.4237 8.42094 14.0167 8.06957 13.2125L5.05084 14.7338C5.92019 16.4133 7.55199 17.2305 10.0269 17.2305C11.3614 17.2305 12.5281 16.918 13.4963 16.3019C14.8679 15.4274 15.1571 13.6344 14.7331 12.3157C14.193 10.6337 12.351 10.0976 10.7071 9.62478Z"
      fill="#3D4ED7"
    />
    <path d="M3.77692 5.43701H0.29306V16.9607H3.77692V5.43701Z" fill="#3D4ED7" />
  </svg>
)
