import styled from 'styled-components'

export interface DividerProps {
  variation?: 'primary' | 'secondary'
}

export const Divider = styled.hr<DividerProps>(
  ({
    theme: {
      primitiveTokens: {
        colors: { gray },
        spacing,
      },
    },
    variation = 'primary',
  }) => ({
    margin: `${spacing[4]} 0`,
    border: 'none',
    borderBottom: `1px solid ${variation === 'secondary' ? gray[5] : gray[10]}`,
  })
)
