import styled from 'styled-components'
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'

import { HumanizedTokens } from 'utils'

type FormControlLabelTokens = HumanizedTokens['components']['formControlLabel']

const baseLabel = (formControlLabel: FormControlLabelTokens) => `
  margin: 0;
  .MuiFormControlLabel-label {
    margin-right: 15px;
    color: ${formControlLabel.base.color};
    user-select: none;
  }
  &.Mui-disabled .MuiFormControlLabel-label {
    color: ${formControlLabel.disabled.color};
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: { formControlLabel },
      },
    },
  }) => `
    ${baseLabel(formControlLabel)}
  `}
`
