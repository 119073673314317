import styled from 'styled-components'
import { omit } from 'ramda'
import TableCell from '@material-ui/core/TableCell'

const StyledTableCell = styled(props => <TableCell {...omit(['isOpen'], props)} />)`
  border-width: ${({ isOpen }) => (isOpen ? 1 : 0)}px;
  transition: height ${props => props.theme.humanizedTokens.generic.transition.small};
  color: inherit;
  width: ${props => props.width};
  height: ${props => props.height}px;
  overflow: hidden;
`

export default StyledTableCell
