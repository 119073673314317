import { Cancel } from '@material-ui/icons'
import { HTMLAttributes } from 'react'
import { SideMenuSearchClose as StyledSideMenuSearchClose } from './styles'

export interface SideMenuSearchCloseProps extends HTMLAttributes<HTMLElement> {
  isVisible: boolean
}

export const SideMenuSearchClose = (props: SideMenuSearchCloseProps) => (
  <StyledSideMenuSearchClose {...props}>
    <Cancel />
  </StyledSideMenuSearchClose>
)
