import styled from 'styled-components'
import { SideMenuSearchCloseProps } from './index'

export const SideMenuSearchClose = styled.i<SideMenuSearchCloseProps>(
  ({
    theme: {
      primitiveTokens: {
        colors: { gray },
      },
    },
    isVisible,
  }) => ({
    position: 'absolute',
    top: 20,
    right: 12,
    padding: 4,
    cursor: 'pointer',
    color: gray[40],
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.2s',
    svg: {
      verticalAlign: 'bottom',
      fontSize: '1rem',
    },
  })
)
